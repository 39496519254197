<template>
  <component is="div">
    <b-modal
      id="modal-lg"
      ok-title="Assign to Account"
      centered
      size="llg"
      :title="`Search for ${stage}s`"
      ref="modal"
      @hidden="() => $emit('update:is-assign-profile-open', false)"
      :visible="isAssignProfileOpen"
      :hide-footer="true"
    >
      <b-card no-body>
        <b-row>
          <b-col cols="12" lg="6">
            <b-pagination v-model="currentPage" :total-rows="totalResults" :per-page="perPage" aria-controls="table-test"></b-pagination>
          </b-col>

          <b-col cols="12" md="6">
            <div class="d-flex justify-content-end">
              <b-button variant="primary" target="_blank" :to="createAndReturn"> Create Profile</b-button>
            </div>
          </b-col>
        </b-row>

        <b-table
          id="table-test"
          :items="profileProvider"
          :fields="profileFields"
          :per-page="perPage"
          :current-page="currentPage"
          :filter="searchTerm"
          :responsive="true"
        >
          <template #cell(funding)="data">
            {{ title(data.item.attributes.fundingSource) }}
          </template>

          <template #cell(session)="data">
            {{ data.item.session ? data.item.session.name : 'n/a' }}
          </template>

          <template #cell(arAccount)="data">
            {{ data.item.arAccount ? data.item.arAccount.name : 'n/a' }}
          </template>

          <template #cell(pathway)="data">
            {{ data.item.pathway ? title(data.item.pathway.name) : 'n/a' }}
          </template>

          <template #cell(subject)="data">
            {{ data.item.subject ? title(data.item.subject.name) : 'n/a' }}
          </template>

          <template #cell(pathwayDate)="data">
            {{ data.item.pathwayDate ? moment(data.item.pathwayDate).utc().format('l') : 'n/a' }}
          </template>

          <template #cell(createdAt)="data">
            {{ moment(data.item.createdAt).utc().format('l') }}
          </template>

          <template #cell(status)="data">
            {{ title(data.item.status) }}
          </template>

          <template #cell(actions)="data">
            <b-button
              v-b-tooltip.hover
              :title="data.item.arAccount ? 'Already assigned to an account' : 'Assign profile'"
              :disabled="isBusy || data.item.arAccount"
              @click="selectProfile(data.item)"
              variant="primary"
              size="sm"
            >
              Select
            </b-button>
          </template>
        </b-table>
      </b-card>
    </b-modal>
  </component>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BInputGroup,
  BPagination,
  BRow,
  BTable,
  BTooltip,
} from 'bootstrap-vue';

import Cleave from 'vue-cleave-component';
import { VueGoodTable } from 'vue-good-table';
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import Prism from 'vue-prism-component';
import store from '@/store';
import { ref } from '@vue/composition-api';
import rosterStoreModule from '@/store/roster/rosterStoreModule';

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { useToast } from 'vue-toastification/composition';
import studentStoreModule from '@/store/student/studentStoreModule';
import moment from 'moment';
import { title } from '@core/utils/filter';

export default {
  components: {
    Cleave,
    VueGoodTable,
    BAvatar,
    BCol,
    BRow,
    BButton,
    BTable,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BFormCheckbox,
    BFormDatepicker,
    BInputGroup,
    Prism,
    BDropdownItem,
    BDropdown,
    BCard,
    BTooltip,
  },
  data() {
    return {
      isBusy: false,
      searchTerm: '',
      perPage: 10,
      currentPage: 1,
      totalResults: 0,

      profileFields: [
        { key: 'actions' },
        // { key: 'arAccount' },
        { key: 'session' },
        // { key: 'pathway' },
        // {key: 'subject'}, // Todo: Renable
        { key: 'funding' },
        { key: 'pathwayDate', sortable: true, label: 'Start' },
        { key: 'createdAt', sortable: true, label: 'Created' },
        { key: 'status', sortable: true },
      ],

      stage: 'profile',
      profile: { id: undefined },

      sortBy: 'startDate',
      sortDesc: true,

      createAndReturn: {
        name: 'apps-roster-list-pending',
        query: {
          op: 'create',
          studentId: this.studentData.id,
        },
      },
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },
    };
  },
  props: {
    isAssignProfileOpen: {
      type: Boolean,
    },
    studentData: {
      type: Object,
      default: () => {},
    },
    profileData: {
      type: Object,
      default: () => {},
    },
    accountData: {
      type: Object,
      default: () => {
        return { id: undefined };
      },
    },
  },
  emits: ['refresh'],
  watch: {
    isAssignProfileOpen(val) {
      if (val) {
        this.stage = 'profile';
        this.profile = undefined;
      }
    },
    restricted(val) {
      this.$refs.refSessionTable.refresh();
    },
  },
  methods: {
    profileProvider(ctx) {
      const promise = store.dispatch('rosterStoreModule/fetchProfiles', {
        search: ctx.filter,
        limit: ctx.perPage,
        page: ctx.currentPage,
        sortBy: `${ctx.sortBy ? ctx.sortBy : 'createdAt'}:${ctx.sortDesc ? 'desc' : 'asc'}`,

        // student: this.studentData.id,
        // populate: 'session pathway subject',
        includeArAccount: true,
      });

      return promise
        .then((response) => {
          const { results, totalResults } = response.data;
          this.totalResults = totalResults;

          // if (results.length === 0) {
          //   console.log(`create + return`, this.createAndReturn);
          //   this.$router.push(this.createAndReturn);
          // }

          return results;
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching session list-pending',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });

          return [];
        });
    },
    selectProfile(profile) {
      this.profile = profile;

      const accountId = this.accountData.id;
      if (!accountId) {
        return this.toast({
          component: ToastificationContent,
          props: {
            title: 'Failed to load account information',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }

      const profileId = profile.id;
      if (!profileId) {
        return this.toast({
          component: ToastificationContent,
          props: {
            title: 'No profile selected to assign',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }

      store
        .dispatch('rosterStoreModule/assignProfileToArAccount', { profileId, accountId })
        .then((response) => {
          const { data: session } = response;

          this.$emit('update:is-assign-profile-open', false);
          this.$emit('refresh', true);

          return this.$toast({
            component: ToastificationContent,
            props: {
              icon: 'EditIcon',
              variant: 'success',

              title: 'Added profile to account',
              text: 'This profile has been assigned to this account',
            },
          });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to assign profile',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });
        });
    },
    handleSearch(searching) {
      this.searchTerm = searching;
    },
  },
  mounted() {},
  setup({ props }) {
    const sessionData = ref(null);

    const toast = useToast();

    return { toast, title, moment };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
