import { render, staticRenderFns } from "./CohortAccountList.vue?vue&type=template&id=3de4269a&scoped=true&"
import script from "./CohortAccountList.vue?vue&type=script&lang=js&"
export * from "./CohortAccountList.vue?vue&type=script&lang=js&"
import style0 from "./CohortAccountList.vue?vue&type=style&index=0&id=3de4269a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3de4269a",
  null
  
)

export default component.exports